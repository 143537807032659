const users = [
  {
    id: '1',
    slug: 'javi010764',
    firstName: 'Javi',
    lastName: 'Gutierrez',
    displayName: 'Javi Gutierrez',
    bio: '<div>"NICK CAGE IS MY</div><img src="images/javi_profile_superman_animated.gif" style="width:8.5em;height:auto;" /><div class="hp__pb__bio--not-beginning">"</div>',
    gender: 'Male',
    age: 46,
    location: 'Mallorca',
    country: 'Spain',
    onlineStatus: 1,
    mood: '<div style="float:left;">waiting for divine inspiration</div><div> <img src="images/javi_profile_status_stars.png" /></div>',
    listening: 'City of angels: Music from the Motion Picture',
    links: [
      {
        id: 'cagespace',
        url: 'https://mycagespace.com/nickcagemovie',
      },
      {
        id: 'twitter',
        url: 'https://twitter.com/nickcagemovie',
      },
      {
        id: 'facebook',
        url: 'https://facebook.com/nickcagemovie',
      },
      {
        id: 'instagram',
        url: 'https://instagram.com/nickcagemovie',
      },
    ],
    moviesURL:'https://search.yahoo.com/search?p=Nick+Cage+movies&fr=yfp-t&ei=UTF-8&fp=1',
    music:'BLACKPINK in your areeea!!',
    television:'I prefer movies',
    books:'I prefer screenplays',
    heroes:'my/onetruegod',
    profilePic: 'javi_profile_pic.jpg',
    profileBg: 'javi_profile_main_bg.jpg',
    blurb: '<div class="hp__blurb-headline";">Wanna be in my Top 8?</div><div class="hp__blurb-body">Hola, my friends! Do you think you are among the top Nick Cage fans in ALL of CageSpace? I would love to feature you - click that green <span style="color:#f8227f; font-size: 1.1em; font-weight: 900;">"ADD TO FRIENDS"</span> button and submit your photo. You may end up in my Top 8 and get some leftover items from my museum renovation ;)</div><div class="hp__blurb-footer"><img src="images/profile_blurb_emoji.png" style="width:20px;height:auto;" />In Cage We Trust!<img src="images/profile_blurb_emoji.png" style="width:20px;height:auto;" />',
    blogs: [
      {
        image: '',
        headline: 'ANOTHER CAGE MASTERPIECE!',
        date: 'April 12, 2022 11:58am',
        body: '<div class="hf__blog-body">The museum gallery is really shaping up now, check out this stunning new piece. Reflections of absolute genius!</div><div class="hf__blog-picture hf__blog-picture--columns"><div class="hf__blog-picture__column"><a class="hf__blog-body" href ="https://www.instagram.com/p/CcDyOsvOlPg/" target="_blank"><img src="images/profile_blog4_image1.jpg" /></a><a class="hf__blog-body" href="https://www.instagram.com/johnguydo/" target="_blank">@johnguydo</a></div></div>'
      },
      {
        image: '',
        headline: 'MUSEUM ART PART DEUX!',
        date: 'April 6, 2022 11:36am',
        body: '<div class="hf__blog-body">The latest artwork has arrived for my museum! Behold, the 3D shaman Nick Cage. Go forth and be cool.</div><div class="hf__blog-picture hf__blog-picture--columns"><div class="hf__blog-picture__column"><a class="hf__blog-body" href="https://www.instagram.com/p/Cb7sQXGg0sQ/" target="_blank"><video width="420" autoplay muted loop><source src="images/profile_blog3_video1.mp4" type="video/mp4"></video></a><a class="hf__blog-body" href="https://www.instagram.com/joeparente34/" target="_blank">@joeparente34</a></div></div>'
      },
      {
        image: '<img src="images/profile_blog_image2.png" />',
        headline: 'NEW MUSEUM ART!',
        date: 'April 2, 2022 11:55am',
        body: '<div class="hf__blog-body">Ordered these incredible Nick Cage art pieces, and they just arrived! Can\'t wait to show you all what they look like in my museum.</div><div class="hf__blog-picture hf__blog-picture--columns"><div class="hf__blog-picture__column"><a class="hf__blog-body" href ="https://www.instagram.com/p/CbX3bMLqikR/" target="_blank"><img src="images/profile_blog_image2_1.png" /></a><a class="hf__blog-body" href="https://www.instagram.com/richardwilkinsonart/" target="_blank">@richardwilkinsonart</a></div><div class="hf__blog-picture__column"><a class="hf__blog-body" href="https://www.instagram.com/p/Ca25boKPKEu/" target="_blank"><img src="images/profile_blog_image2_2.png" /></a><a class="hf__blog-body" href="https://www.instagram.com/thebutcherbilly/" target="_blank">@thebutcherbilly</a></div></div>'
      },
      {
        image: '<img src="images/profile_blog_image.jpg" />',
        headline: 'WATCH THIS (CAGE) SPACE',
        date: 'Mar 29, 2022 10:06am',
        body: '<div class="hf__blog-body">Making major renovations to my <span style="font-weight: 900">MUSEUM PROJECT</span>. It\'s gonna be EPIC! A couple of incredible pieces arrive tomorrow, I\'m so excited. I\'ll share more SOON!</div><div class="hf__blog-picture"><img src="images/profile_blog_image.jpg" /></div>'
      },
      
    ],
    blog: 
      {
        image: '<img src="images/profile_blog_image.jpg" />',
        headline: 'WATCH THIS (CAGE) SPACE',
        date: 'Mar 29, 2022 10:06am',
        body: "<div>Making major renovations to my <span style='font-weight: 900'>MUSEUM PROJECT</span>. It's gonna be EPIC! A couple of incredible pieces arrive tomorrow, I'm so excited. I'll share more SOON!</div>"
      },
    blogTwo: 
      {
        image: '<img src="images/profile_blog_image2.png" />',
        headline: 'NEW MUSEUM ART!',
        date: 'April 2, 2022 11:55am',
        body: "<div>Ordered these incredible Nick Cage art pieces, and they just arrived! Can't wait to show you all what they look like in my museum.</div>"
      },
    friends: [
      {
        id: 'friends1',
        userid: 'Sara Storm',
        image: '<img src="images/friends_profile_3_1.jpg" />',
      },
      {
        id: 'friends2',
        userid: 'Lio Garner',
        image: '<img src="images/friends_profile_3_2.jpg" />',
      }, 
      {
        id: 'friends3',
        userid: 'Hannah Barasz',
        image: '<img src="images/friends_profile_3_3.jpg" />',
      },    
      {
        id: 'friends4',
        userid: 'Marlon Alcivar',
        image: '<img src="images/friends_profile_3_4.jpg" />',
      },  
      {
        id: 'friends5',
        userid: 'Abraham Cantu',
        image: '<img src="images/friends_profile_3_5.jpg" />',
      },  
      {
        id: 'friends6',
        userid: 'Sharon Rose',
        image: '<img src="images/friends_profile_3_6.jpg" />',
      },
      {
        id: 'friends7',
        userid: 'Norma Salas',
        image: '<img src="images/friends_profile_3_7.jpg" />',
      }, 
      {
        id: 'friends8',
        userid: 'Lish Larson',
        image: '<img src="images/friends_profile_3_8.jpg" />',
      },    
    ],
  }
];

export default {
  getUsers() {
    return users;
  },

  getUserById(id) {
    const foundUsers = users.filter((user) => {
      return user.id === id;
    })

    return foundUsers[0];
  },

  getUserBySlug(slug) {
    const foundUsers = users.filter((user) => {
      return user.slug.toLowerCase() === slug.toLowerCase();
    })

    return foundUsers[0];
  }
}