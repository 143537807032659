<template>
  <div class="rotate-overlay fixed-overlay">
    <div class="ro__bg fixed-overlay-bg"></div>
    <div class="ro__content fixed-overlay-content">
      <div class="ro__content-image"></div>
      <div class="ro__content-text">Please rotate to portrait mode for the best viewing experience.</div>
    </div>
  </div>
</template>

<style lang="scss">
.rotate-overlay {
  display: none;
}

.ro__content {
  // width: 80%;
  text-align: center;
}

.ro__content-text {
  width: 80%;
  margin: 0 auto;
  font-size: 1.5em;
  // color: $profile-color-text;
}

.ro__content-image {
  width: 30vw;
  margin: 0 auto 1em auto;
  background: url(~@/assets/overlay_rotate_icon.png) 0 0 no-repeat;
  background-size: contain;

  &::before {
    content: '';
    display: block;
    padding-top: calc(327 / 359 * 100%);
  }
}

@include mobile-landscape {
  .rotate-overlay {
    display: block;
  }
}
</style>