<template>
  <div class="home page" v-if="user != null">
    <div class="home__bg" :style="{backgroundImage:userProfileBgUrl}"></div>
    <div class="home__sections page-section">
      <div class="home__section-info">
        <div class="home__profile home__section-container">
          <div :class="[isAndroid ? 'dotted-lights-bg--android' : 'hp__bg dotted-lights-bg']"></div>
          <div class="hp__profile-container home__section-content-container">
            <div class="hp__name hc__header trajan-pro">{{user.displayName}}</div>
            <div class="hp__profile-info">
              <div class="hp__profile-picture"><img :src="userProfilePicUrl" /></div>
              <div class="hp__profile-bio">
                <div class="hp__pb__bio" v-html="user.bio"></div>
                <!-- <div class="hp__pb__bio"><div>"My IS  </div><img src="images/javi_profile_superman_animated.gif" /><div class="hp__pb__bio--not-beginning"> !"</div></div> -->
                <div class="hp__pb__gender">{{user.gender}}</div>
                <div class="hp__pb__age">{{user.age+' years old'}}</div>
                <div class="hp__pb__location">{{user.location}}</div>
                <div class="hp__pb__country">{{user.country}}</div>
                <div class="text-with-icon-container hp__pb__online-status">
                  <template v-if="user.onlineStatus">
                    <div class="twic__icon hp__pb__online-status-icon"><img src="@/assets/profile_icon_online.png" /></div>
                    <div class="twic__label hp__pb__online-status-label">Online Now!</div>
                  </template>
                  <template v-else>
                    <div class="twic__label hp__pb__online-status-label">Offline</div>
                  </template>
                </div>
              </div>
            </div>
            <div class="hp__mood-listening-container">
              <div class="hp__mood">
                <div class="hp__ml-label">Mood:&nbsp;</div>
                <div class="hp__ml-value" v-html="user.mood"></div>
              </div>
            
            </div>
          </div>
        </div>
        <div class="home__contact home__section-container">
          <!-- <div class="hc__bg dotted-lights-bg"></div> -->
          <div :class="[isAndroid ? 'dotted-lights-bg--android' : 'hc__bg dotted-lights-bg']"></div>
          <div class="hc__content-container home__section-content-container">
            <div class="hc__header trajan-pro home-content-header">Contacting {{user.firstName}}</div>
            <div class="hc__contact-items-container">

              <!-- <div class="hc__add-friends hc__contact-item text-with-icon-container hc__add-friends-animation" @click="addToFriends">
                <div class="hc__contact-item__icon twic__icon"><img src="@/assets/profile_contact_icon_add_to_friends.png" /></div>
              </div> -->

              <div class="hc__add-friends hc__contact-item text-with-icon-container" @click="addToFriends">
                <div class="hc__contact-item__icon twic__icon"><img src="@/assets/add_to_friends_v2.gif" /></div>
              </div>

              <div class="hc__forward-friends hc__contact-item text-with-icon-container" @click="forwardToFriends">
                <div class="hc__contact-item__icon twic__icon"><img src="@/assets/profile_contact_icon_forward_to_friends.png" /></div>
                <div class="twic__label">Forward to Friends</div>
              </div>
            </div>
            <!-- <div class="hc__instant-message hc__contact-item" @click="sendMessage">Instant Message</div> -->
          </div>
        </div>
        <div class="home__links home__section-container">
          <!-- <div class="hl__bg dotted-lights-bg"></div> -->
          <div :class="[isAndroid ? 'dotted-lights-bg--android' : 'hl__bg dotted-lights-bg']"></div>
          <div class="hl__content-container home__section-content-container">
            <div class="hc__header trajan-pro home-content-header">{{user.firstName+"'s Urls"}}</div>
            <template v-if="user.links.length > 0">
              <div v-for="(link, index) in user.links" :key="'link-'+index" class="hl__link-item"><a :href="link.url" target="_blank">{{link.url}}</a></div>
            </template>
          </div>
        </div>

        <div class="home__links home__section-mmp">
           <!-- INSERT PLAYER HERE -->
          <div class="mmp-button-container">
              <button type="button" class="mmp-playPauseBtn-container">
              <!-- <i class="mmp-icon material-icons mmp-play-icon"></i> -->
                <img v-if="!isPlaying" @click="play()" class="mmp-playBtn-icon" src="@/assets/play.png" alt="play icon">
                <img v-else @click="pause()" class="mmp-pauseBtn-icon" src="@/assets/pause.png" alt="pause icon">
              </button>
          </div>

          <div class="mmp-display-container">
              <div class="mmp-song-info-container">
                <div class="mmp-song-name">{{ current.title }}</div>
                <div class="mmp-artist-name">{{ current.artist }}</div>
              </div>
          
              <div class="mmp-eq-container">
                <img v-if="isPlaying" class="mmp-equalizer-on" src="@/assets/eq_play.gif" alt="Eq Icon on">
                <img v-else class="mmp-equalizer-off" src="@/assets/eq_pause.png" alt="Eq Icon off">
              </div>
          </div>

          <audio src="@/assets/m_The Turtles_Happy Together.mp3" id="audio"></audio>
        </div>

        <div class="home__links home__section-container">
          <!-- <div class="hl__bg dotted-lights-bg"></div> -->
          <div :class="[isAndroid ? 'dotted-lights-bg--android' : 'hl__bg dotted-lights-bg']"></div>
          <div class="hl__content-container home__section-content-container-interests">
            <div class="hc__header trajan-pro home-content-header">{{user.firstName+"'s Interests"}}</div>
            </div>

          <div class="hl__content-container home__section-content-container-interests" style="top:-1px;">
            <div class="hc__contact-items-container">
             <div class="hc__interest-subject trajan-pro"> MOVIES</div>
             <div class="hc__interest-value"> <a :href="user.moviesURL" target="_blank">{{user.moviesURL}}</a></div>
            </div>
          </div>

            <div class="hl__content-container home__section-content-container-interests" style="top:-2px;">
              <div class="hc__contact-items-container">
                <div class="hc__interest-subject trajan-pro"> MUSIC</div>
                <div class="hc__interest-value">{{user.music}}</div>
            </div>
          </div>

          <div class="hl__content-container home__section-content-container-interests" style="top:-3px;">
              <div class="hc__contact-items-container">
                <div class="hc__interest-subject trajan-pro"> TELEVISION</div>
                <div class="hc__interest-value">{{user.television}}</div>
            </div>
          </div>

          <div class="hl__content-container home__section-content-container-interests" style="top:-4px;">
              <div class="hc__contact-items-container">
                <div class="hc__interest-subject trajan-pro"> BOOKS</div>
                <div class="hc__interest-value">{{user.books}}</div>
            </div>
          </div>

           <div class="hl__content-container home__section-content-container-interests" style="top:-5px;">
              <div class="hc__contact-items-container">
                <div class="hc__interest-subject trajan-pro"> HEROES</div>
                <div class="hc__interest-value">{{user.heroes}}</div>
            </div>
          </div>

        </div>
      </div>
      
      <div class="home__feed">
        <div class="hf__content home__section-container">
          <!-- <div class="hf__bg dotted-lights-bg"></div> -->
          <div :class="[isAndroid ? 'dotted-lights-bg--android' : 'hf__bg dotted-lights-bg']"></div>
          <div class="hf__content-container home__section-content-network hc--center-align">
            <div class="hp__name hc__header trajan-pro hc--inline-display"> {{user.firstName}}&nbsp;{{user.lastName}}&nbsp;</div><div class="hp__name hc__header trajan-pro-bold hc--inline-display">is in your extended network</div>
          </div>
        </div>

        <div class="hf__content--spacer home__section-container">
          <!-- <div class="hf__bg dotted-lights-bg"></div> -->
          <div :class="[isAndroid ? 'dotted-lights-bg--android' : 'hf__bg dotted-lights-bg']"></div>
          <div class="hf__content-container home__section-content-container-friends">
          <div class="hp__name hc__header trajan-pro hc--inline-display">{{user.firstName+"'s BLURB"}}</div>
            <div class="hf__friends-holder">
              <div class="hp__blurb-picture"><img src="images/profile_blurb_top8.gif" /></div>
            </div>
            <div class="hp__blurb-content" v-html="user.blurb"></div>
         </div>
        </div>


      <div class="hf__content--spacer home__section-container">
          <!-- <div class="hf__bg dotted-lights-bg"></div> -->
       <div :class="[isAndroid ? 'dotted-lights-bg--android' : 'hf__bg dotted-lights-bg']"></div>
         <div class="hf__content-container home__section-content-container-friends">
          <div class="hp__name hc__header trajan-pro hc--inline-display">{{user.firstName+"'s FRIEND SPACE"}}</div>
           <div class="hf__friends-holder">
              <template v-if="user.friends.length > 0">
                <div v-for="(friend, index) in user.friends" :key="'friend-'+index" class="hf__friends-holder-child">
                    <div class="hf__friends-name">{{friend.userid}}</div>
                    <div class="hf__friends-picture" v-html="friend.image" ></div>
                </div>
            </template>
            </div>
         </div>
      </div>
        
      <div class="hf__content--spacer home__section-container">
        <!-- <div class="hf__bg dotted-lights-bg"></div> -->
        <div :class="[isAndroid ? 'dotted-lights-bg--android' : 'hf__bg dotted-lights-bg']"></div>
        <div class="hf__content-container home__section-content-container">
          <div class="hp__name hc__header trajan-pro hc--inline-display">{{user.firstName+"'s BLOG ENTRIES"}}</div>
            <div class="hf__blog-content hf__blog-content--no-border" v-for="(item, index) in user.blogs">
              <div class="hf__blog-headline">{{item.headline}}</div>
              <div class="hf__blog-date">{{item.date}}</div>
              <div v-html="item.body"></div>
            </div>
            <!-- <div class="hf__blog-content hf__blog-content--no-border">
              <div class="hf__blog-headline">{{user.blogTwo.headline}}</div>
              <div class="hf__blog-date">{{user.blogTwo.date}}</div>
              <div class="hf__blog-body" v-html="user.blogTwo.body"></div>
              <div class="hf__blog-picture hf__blog-picture--columns">
                <div class="hf__blog-picture__column">
                  <a class="hf__blog-body" href ="https://www.instagram.com/p/CbX3bMLqikR/" target="_blank">
                    <img src="images/profile_blog_image2_1.png" />
                  </a>
                  <a class="hf__blog-body" href="https://www.instagram.com/richardwilkinsonart/" target="_blank">@richardwilkinsonart</a>
                </div>
                <div class="hf__blog-picture__column">
                  <a class="hf__blog-body" href="https://www.instagram.com/p/Ca25boKPKEu/" target="_blank">
                    <img src="images/profile_blog_image2_2.png" />
                  </a>
                  <a class="hf__blog-body" href="https://www.instagram.com/thebutcherbilly/" target="_blank">@thebutcherbilly</a>
                </div>
              </div>
            </div>
            <div class="hf__blog-content  hf__blog-content--no-border">
              <div class="hf__blog-headline">{{user.blog.headline}}</div>
              <div class="hf__blog-date">{{user.blog.date}}</div>
              <div class="hf__blog-body" v-html="user.blog.body"></div>
              <div class="hf__blog-picture"><img src="images/profile_blog_image.jpg" /></div>
            </div> -->
          </div>
        </div>
      </div>
      
    </div>
    
  </div>
</template>

<script>
// @ is an alias to /src
import { ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

import UserRepository from '@/api/repositories/UserRepository';

// import MusicPlayer from '@/api/repositories/MusicPlayer';

export default {
  name: 'Home',
  components: {
    // appMusicPlayer: MusicPlayer
  },
  props: {

  },
  data () {
    return {
      current: {},
      index: 0,
      isPlaying: false,
      song: [
        {
          title: 'Happy Together',
          artist: 'The Turtles',
          src: require('../assets/m_The Turtles_Happy Together.mp3')
        },
      ],
      player: new Audio()
    }
  },
  created () {
      this.current = this.song[this.index];
      this.player.src = this.current.src;
      // this.player.play();
      this.player.onended = () => {
        this.isPlaying = false;
      }
  },
  methods: {
    play () {
      this.player.play();
      this.isPlaying = true;
      console.log("song is playing");

    },
    pause () {
      this.player.pause();
      this.isPlaying = false;
      console.log("song is paused");
    }
  },

  setup(props) {
    const route = useRoute();
    const store = useStore();

    // Music PLayer



    // Music Player


    let userSlugParam = null;

    if( route.params.slug ) {
      userSlugParam = encodeURIComponent(route.params.slug);
    }

    console.log('userSlugParam:', userSlugParam);

    // console.log(route.params);

    // console.log(UserRepository.getUsers());
    

    // User
    let user = ref(null);
    let userProfilePicUrl = ref(null);
    let userProfileBgUrl = ref(null);

    if( userSlugParam != null ) {
      user = UserRepository.getUserBySlug(userSlugParam);
      console.log('found slug, user is', user);

      if( user == null ) {
        user = UserRepository.getUsers()[0];
      }
    } else {
      user = UserRepository.getUsers()[0];
    }

    if( user ) {
      userProfilePicUrl.value = 'images/'+user.profilePic;
      userProfileBgUrl.value = "url('images/"+user.profileBg+"')";
    }

    console.log(user);


    const addToFriends = () => {
      store.commit('disableScroll', {value:true});
      store.commit('updateSignUpOverlay', {value:true});
    }

    const forwardToFriends = () => {
      store.commit('disableScroll', {value:true});
      store.commit('updateShareOverlay', {value:true});
    }

    const sendMessage = () => {
      
    }

    return {
      user,
      userProfilePicUrl,
      userProfileBgUrl,

      addToFriends,
      forwardToFriends,
      sendMessage,

      isAndroid: computed(() => store.state.os.android),
    }
  },
}
</script>


<style lang="scss">
.home__bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.3);
  background-repeat: repeat-x repeat-y;
  // background-image: url('~@/images/javi_profile_main_bg.jpg');
}

.home__sections {
  position: relative;
  display: flex;
  // width: 100%;
}

.home__section-container {
  position: relative;
  font-size: 1em;
  padding: 0.75em 0.75em;
  // background: rgba(0, 0, 0, 0.8);
  background: rgb(42,254,252,0.9);
}

.home__section-content-container {
  position: relative;
  padding: 0.5em 1.5em 1.5em 1.5em;
  border: 1px solid $profile-color-border;
}


.home__section-content-container-friends {
  position: relative;
  padding: 0.5em 1.5em 0em 1.5em;
  border: 1px solid $profile-color-border;
}

.home__section-content-network {
  position: relative;
  border: 1px solid $profile-color-border;
  text-align: center;
  padding: 3.25em 2em 3.5em;

  .hc__header {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.home__section-content-container-interests{
  position: relative;
  padding: 0.5em 1.5em 0.5em 1.5em;
  border: 1px solid $profile-color-border;
}

.home__section-info {
  font-size: 1em;
  width: calc(42% - 1.0em);
  padding: 0em 0em 0.25em 0em;
  margin-right: 1.0em;
}

.home__profile {
  position: relative;
  // font-size: 1em;
  // padding: 2em 2em 2em 2em;
  // background: rgba(0, 0, 0, 0.8);
}

.hp__name {
  position: relative;
  font-size: 2.25em;
}

.hp__profile-info {
  position: relative;
  display: flex;
  margin: 0.25em 0 0 0;
}

.hp__profile-picture {
  width: 50%;

  & img {
    width: 100%;
  }
}

.hp__blurb-picture {
  img {
    max-width: 100%;
  }
}

.hp__blurb-headline {
  font-size: 1.2em;
  font-weight: 900;
  margin-bottom: 10px;
}

.hp__blurb-footer {
  font-weight: 900;
  margin-bottom: 25px;
}

.hp__blurb-body {
  font-size: 1em;
  margin-bottom: 10px;
}

.hp__blurb-content {
  color: $profile-color-text;
  font-size: 1.7em;
  font-weight: 500;
  letter-spacing: .1rem;
}

.hf__blog-content {
  border-top: 1px solid #1844e7;
  padding-top:2em;
  margin: 0 0 1em 0;
}


.hf__blog-content--no-border{
  border-top: 0 !important;
  padding-top:0 !important;
}

.hf__blog-picture {
  margin-bottom: 10px;

  * {
    max-width: 100%;
  }

  &.hf__blog-picture--columns {
    display: flex;
  }
}

.hf__blog-picture__column {
  display: block;
  margin: 0 1.0em 0 0;
  // padding: 0 0 1.5em 0;
  max-width: calc((100% - 1em)/2);

  & * {
    display: block;
  }

  &:last-child {
    margin-right: 0;
  }
}

.hf__blog-headline {
  color: $profile-color-text;
  font-weight: 700;
  font-size: 1.7em;
  letter-spacing: .1rem;
}

.hf__blog-date {
  color: $profile-color-online-status;
  font-weight: 600;
  font-size: 1.3em;
  letter-spacing: .05rem;
  margin-bottom: 10px;
}

.hf__blog-body {
  color: $profile-color-text;
  font-weight: 400;
  font-size: 1.4em;
  letter-spacing: .05rem;
  padding-bottom: 1em;
}

$bio-text-indent: 1em;

.hp__profile-bio {
  width: calc(50% - 2em);
  font-size: 1.6em;
  font-weight: 600;
  padding: 0.25em 1.0em;
  color: $profile-color-text;

  // & *:first-child {
    text-indent: $bio-text-indent;
  // }

  // & .hp__pb__bio--not-beginning {
  //   text-indent: 0;
  // }
}

.hp__pb__bio {
  position: relative;
  color: $profile-color-bio;
  // vertical-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 0;

  max-width:95%;

  & *:not(:first-child) {
    text-indent: 0;
  }

  img {
    height: 2.25em;
  }

  // & * {
  //   margin-top: auto;
  //   margin-bottom: auto;
  // }
}

.hp__pb__gender {
  position: relative;
  margin: 0.75em 0 0 0;
}

.hp__pb__age {

}

.hp__pb__location {

}

.hp__pb__country {

}

.hp__pb__online-status {
  position: relative;
  color: $profile-color-online-status;
  margin: 1.5em 0 0 0;
  // display: flex;
  // align-items: center;
}

.hp__pb__online-status-icon {
  width: 1.5em;
  margin-right: 0.5em;
  margin-top: 0.3em;

  // & img {
  //   width: 100%;
  // }
}

.hp__pb__online-status-label {
  
}

.hp__mood-listening-container {
  position: relative;
  display: flex;
  margin: 1em 0 0 0;
  color: $profile-color-bio;
  font-size: 1.5em;
}

.hp__mood {
  position: relative;
  width: 100%;
  margin-right: 0.25em;
}

.hp__listening {
  position: relative;
  width: 50%;
  margin-left: 0.25em;
}

.hp__ml-label {
  font-weight: 700;
  float: left;
}

.hp__ml-value {

}

.home__contact {
  margin: 2.0em 0 0 0;
}

.hc__header {
  // font-size: 1.5em;
    margin-bottom: 0.7em;
    margin-top: 0.5em;
}

.hc__contact-items-container {
  position: relative;
  display: flex;
}

.hc__contact-item {
  position: relative;
  font-size: 1.6em;
  // display: flex;
  // padding-left: 2em;
  margin: 0 0 0 0;
  // background-size: 1.5em;
  // background-repeat: no-repeat;
  // background-position: 0 80%;
  width: 50%;
  cursor: pointer;

  // &.hc__add-friends {
  //   // background-image: url('~@/assets/profile_contact_icon_add_to_friends.png');
  //   padding: 0.7em 0.2em;
  //   margin: 0 0.25em 0 0;
  //   background: linear-gradient(180deg, rgba(2,255,78,1) 0%, rgba(2,189,58,1) 100%);

  //   border-radius: 7px;
  //   border: 1px solid rgba(0,0,0,0.8);    

  //   -moz-box-shadow: inset 2px -2px 2px rgba(0, 0, 0, 0.5);
  //   -webkit-box-shadow: inset 2px -2px 2px rgba(0, 0, 0, 0.5);
  //   box-shadow: inset 2px -2px 2px rgba(0, 0, 0, 0.5),inset -2px 0px 2px rgba(255, 255, 255, 0.4);

  //   width: calc(50% - 1.75em);
  //   justify-content: center;

  //   .hc__add-friends-animation {
  //     position: absolute;
  //     top: -2px;
  //     left: -2px;
  //     width: calc(100% - 1.5em);
  //     height: calc(100% - 1em);
  //     font-size: 1em;
  //     color:black;
  //     // box-shadow: 4px 4px 10px rgba(211, 5, 108, 1.0);
  //     // visibility: hidden;
  //     // display: none;
  //     opacity: 1;
  //     animation: hc__add-friends-animation 0.6s alternate infinite;
  //   }
  // }

  &.hc__forward-friends {
    // background-image: url('~@/assets/profile_contact_icon_forward_to_friends.png');
    margin: 0 0 0 0.25em;
    width: calc(50% - 0.25em);
    color: #02fe4e;
    font-weight:700;
      text-shadow: 0 0 5px rgba(0,0,0,0.5);

    & .hc__forward-friends__icon {

    }
  }

  & .hc__contact-item__icon {
    margin: 0.25em 0.25em 0 0;
  }

  // &.hc__instant-message {
  //   background-image: url('~@/assets/profile_contact_icon_message.png');
  // }
}

@keyframes hc__add-friends-animation {
  from {
    // visibility: hidden;
    // display: none;
    opacity: 0;
  }

  29% {
    opacity: 0;
  }

  30% {
    // visibility: visible;
    // display: fixed;
    opacity: 1;
  }

  49% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  95% {
    opacity: 0;
  }

  96% {
    opacity: 1;
  }

  99% {
    opacity: 1;
  }

  to {
    // visibility: hidden;
    // display: none;
    opacity: 0;
  }
}

.hc__contact-item-icon {

}

.hc__contact-item-label {

}

.home__links {
  margin: 2.0em 0 0 0;
}

.hl__header {
  margin-bottom: 0.25em;
}

.hl__link-item {
  margin: 0 0 0.8em 0;
  color: $profile-color-text;
  font-size: 1.5em;
  font-weight:bold;
  

  a {
    color: #f8227f;
  }

}

.home__feed {
  font-size: 1em;
  // padding: 0.25em 0.5em 0.25em 0.5em;
  width: calc(58% - 1.0em);
  margin-left: 1.0em;
}

.hf__content {
  
}

.hf__content--spacer{
    margin: 2em 0 0 0;
}

.hf__warning {
  font-size: 2.5em;
  font-family: 'Times New Roman', Times, serif;
  color: $profile-color-text;
  padding: 0.75em 0.75em;
}

.hf__warning-private {
  color: #ffffff;
}

.home-content-header {
  font-size: 2em;
}

.text-with-icon-container {
  position: relative;
  display: flex;
  align-items: center;

  .twic__icon {

    & img {
      width: 100%;
    }
  }

  .twic__label {

    &.text--shadow{
      // font-weight:700;
    // text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
// color:#38373D;
color:white;
font-weight:bold;
text-shadow: 1px 1px 2px rgba(56,55,61,0.5)
    }
  }
}

@include desktop-medium {

}

@include desktop-small {

}

@include tablet {
  .home__sections {
    display: block;
  }

  .home__section-info {
    width: 100%;
    margin-right: 0;
  }

  .home__feed {
    width: 100%;
    margin: 1.5em 0 0 0;
  }

  .hp__profile-picture {
     width: 30%;
  }

  .hc__contact-item{
    width:30%;
  }
}

@include mobile {
  .hp__profile-bio {
    font-size: 1.3em;
  }

  .hp__mood-listening-container {
    display: block;
  }

  .hp__mood {
    margin-right: 0;
    width: 100%;
  }

  .hp__listening {
    margin-left: 0;
    width: 100%;
    margin-top: 0.5em;
  }

  .hc__contact-item {
    font-size: 1.2em;
  }

  .hl__link-item {
    font-size: 1.3em;
  }

  .hf__warning {
    font-size: 2em;
  }

  .hf__blog-picture {
    &.hf__blog-picture--columns {
      display: block;
    }
  }

  .hf__blog-picture__column {
    padding: 0 0 1.5em 0;

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.hc__interest-subject{
  width: 30%;
  font-size: 1.6em;
  font-weight: 600;
  padding: 0.5em 0em;
  color: $profile-color-text;
}

.hc__interest-value{
  width: calc(70% - 2em);
  font-size: 1.6em;
  font-weight: 600;
  padding: 0.5em 1.0em;
  color: $profile-color-text;
  overflow-wrap: break-word;

  a{
    color:#f8227f;
  }

}

.hf__friends-picture{
   width: 100%;
  //  margin:10px;

  & img {
    width: 100%;
  }
}

.hf__friends-holder{
  display: flex;
  flex-wrap: wrap;
}

.hf__friends-holder-child {
    // display: inline-block;
    // background: blue;
    text-align:center;
    // width: 21%;
    width: calc((100% - 80px)/4);
    padding-left:10px;
    padding-right:10px;
    padding-bottom: 3em; 
}


.hf__friends-name{
  width: 100%;
  font-size: 1.3em;
  font-weight: 600;
  color: $profile-color-text;
  overflow-wrap: break-word;
  padding-bottom: 0.5em;
}

.noscroll{
  overflow: hidden;
  }
  
</style>