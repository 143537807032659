// if (typeof Object.assign != 'function') {
//   Object.assign = function(target, varArgs) {
// 'use strict';
// if (target == null) { // TypeError if undefined or null
//  throw new TypeError('Cannot convert undefined or null to object');
// }

// var to = Object(target);

// for (var index = 1; index < arguments.length; index++) {
//  var nextSource = arguments[index];

//  if (nextSource != null) { // Skip over if undefined or null
//    for (var nextKey in nextSource) {
//      // Avoid bugs when hasOwnProperty is shadowed
//      if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
//        to[nextKey] = nextSource[nextKey];
//        }
//       }
//     }
//   }
//   return to;
//  };
// }

import 'core-js/actual';


import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App);

app.use(store);
app.use(router);

app.mount('#app');

