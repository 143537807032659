import { createStore } from 'vuex';
import axios from 'axios';
import qs from 'qs';
import _ from 'lodash';
import MobileDetect from 'mobile-detect';

const NAMESPACE = '[store]';

let md = new MobileDetect(window.navigator.userAgent);
console.log('mobile:', md.phone());
console.log('android:', md.is('AndroidOS'));

const store = createStore({
  state: {
    signUpOverlay: false,
    shareOverlay: false,
    disableScroll:false,
    enabledScroll:false,
    os: {
      mobile: md.mobile(),
      android: md.is('AndroidOS'),
      ios: md.is('iOS'),
    }
  },
  mutations: {
    updateSignUpOverlay(state, payload) {
      state.signUpOverlay = payload.value;
    },
    updateShareOverlay(state, payload) {
      state.shareOverlay = payload.value;
    },
    disableScroll(state) {
			document.getElementsByTagName("body")[0].style.overflow ="hidden";
      document.getElementsByTagName("html")[0].style.position = "fixed";
      document.getElementsByTagName("html")[0].style.height = "100%";
		},
    enabledScroll(state){
      document.getElementsByTagName("body")[0].style.overflow ="auto";
      document.getElementsByTagName("html")[0].style.position = "static";
      document.getElementsByTagName("html")[0].style.height = "auto";
    }
  },
  actions: {
    submitUserInfo({commit, state}, userData) {
      console.log(NAMESPACE, 'submitUserInfo');
      
      return new Promise((resolve, reject) => {
        // let url = 'https://cagespace-stg.avatarclient.net/api/';
        let url = process.env.VUE_APP_API_URL;
        let data = _.merge({action:'addEntry'}, userData);
        
        console.log('url:', url);
        console.log(data);

        axios({
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: url,
          data: qs.stringify(data),
        })
          .then((response) => {
            console.log(response.data);

            resolve(response.data);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          })
      });
    }
  },
});

export default store;