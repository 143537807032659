<template>
  <header class="main-header">
    <!-- <div class="main-header__bg"></div> -->
    <div class="main-header-content">
      <div class="main-header__left-container">
        <div class="mh__logo" @click.prevent="goToHome()">
          <div class="mh__logo-icon-container"><img class="mh__logo-icon" src="@/assets/site_cagespace_icon.png" /></div>
          <div class="mh__logo-label">MyCageSpace</div>
        </div>
      </div>
      <div class="main-header__right-container">
        <div class="mh__sign-up"><a href="#" @click.stop.prevent="signUp()">Sign Up</a></div>
      </div>
    </div>
  </header>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

const NAMESPACE = '[Header]';

export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    
    const signUp = () => {
      console.log('signUp');

      store.commit('updateSignUpOverlay', {value:true});
    }

    /**
     * goToHome
     * clicks on the logo in the header will redirect user to go to Home if user is not on Home.
     */
    const goToHome = () => {
      // console.log('goToHome');

      // console.log(route.name);

      if( route.name.toLowerCase() !== 'home' ) {
        router.push({path: '/'});
      }
    }

    let header = null;
    let headerHeight = 0;
    let minScrolledHeight = 0;
    
    const updateHeaderStyle = (currentScroll) => {
      if( currentScroll > minScrolledHeight ) {
        header.classList.add('scrolled');
      } else {
        header.classList.remove('scrolled');
      }
    }

    const initHeaderScroll = () => {
      header = document.querySelector(".main-header");

      if( header ) {
        headerHeight = header.offsetHeight;
        minScrolledHeight = headerHeight/2;
        console.log('headerHeight:', headerHeight);

        // let lastScroll = 0;

        updateHeaderStyle(window.pageYOffset);

        window.addEventListener('scroll', () => {
          let currentScroll = window.pageYOffset;
          // console.log('currentScroll:', currentScroll);
          
          updateHeaderStyle(currentScroll);

          // lastScroll = currentScroll;
        })
      }
      // const headerHeight = header.offsetHeight;
      // main.style.top = headerHeight + "px";
      // let lastScroll = 0;
      // window.addEventListener("scroll", () => {
      //   let currentScroll = window.pageYOffset;
      //   if (currentScroll - lastScroll > 0) {
      //     header.classList.add("scroll-down");
      //     header.classList.remove("scroll-up");
      //   } else {
      //     // scrolled up -- header show
      //     header.classList.add("scroll-up");
      //     header.classList.remove("scroll-down");
      //   }
      //   lastScroll = currentScroll;
      // })
    }

    onMounted(() => {
      console.log(NAMESPACE, 'onMounted()');
      
      initHeaderScroll();
    });

    return {
      signUp,
      goToHome,
    }
  },
}

</script>

<style lang="scss" scoped>
.main-header {
  position: fixed;
  top: 0;
  left: 50%;
  font-size: 1rem;
  margin: 1em 0 1em 0;
  width: 100%;
  z-index: 20;
  max-width: $max-content-width;
  transform: translateX(-50%);

  &.scrolled {
    .main-header-content {
      background: rgb(42,254,252,1);
    }
  }
}

.main-header-content {
  position: relative;
  padding: 1em 0.5em 1em 0.5em;
  margin: 0 auto;
  width: calc(100% - 4em);
  // background: rgba(0, 0, 0, 0.8);
  background: rgb(42,254,252,0.9);
  border: solid 1px $border-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-header__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23916e28' stroke-width='4' stroke-dasharray='5%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.main-header__left-container {
  position: relative;
  padding-left: 1em;
}

.main-header__right-container {
  position: relative;
  width: 30%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  padding-right: 1em;
}

.mh__logo {
  font-size: 1.0em;
  display: flex;
  margin-right: 0.5em;
  position: relative;
}

.mh__logo-icon-container {
  // position: absolute;
  // top: 50%;
  // width: 100%;
  // transform: translateY(-50%);
  position: relative;
  margin-right: 0.2em;
  width: 3em;

  &::before {
    content: '';
    display: block;
    padding-top: calc(38/52 * 100%);
  }
}

.mh__logo-icon {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
}

.mh__logo-label {
  font-size: 3em;
  font-weight: 700;
}

.mh__sign-up {
  position: relative;
  font-size: 1.5em;
  font-weight: 700;
  color: #ffffff;
  padding: 0 0.25em 0 0;
  display:none;

  & a {
    color: inherit;
  }
}

@include desktop-small {

}

@include tablet {

}

@include mobile {
  .mh__logo {
    font-size: 0.8em;
  }
}

</style>