<template>
  <div
    :class="[
      'form-field-wrapper',
      dataType == 'textarea' ? 'lrg' : '',
      dataType == 'checkbox' ? 'checkbox' : '',
      (error === true || showError === true) ? 'err' : '',
      checked === true ? 'checked' : '',
    ]"
  >
    <textarea
      v-if="dataType == 'textarea'"
      rows="4"
      cols="10"
      class="form-field"
      :autocomplete="autocomplete"
      :placeholder="defaultText"
      :type="dataType"
      v-model="userInput"
    >
    </textarea>

    <template
      v-else-if="dataType == 'select'">
      <label class="form-field-label" v-show="userInput === null">{{defaultText}}</label>
      <select
        v-bind:value="userInput"
        :class="[
          'form-field',
          userInput === null ? 'form-select-default-text' : ''
        ]"
        :type="dataType"
        @change="onSelectInputChanged"
      >
        <option disabled value="">{{defaultText}}</option>
        <option
          v-for="(option) in selectOptions"
          :value="option.id"
          :key="option.id"
        >
          {{option.value}}
        </option>
        <!-- <option value="BA">Alberta</option>
        <option value="BC">British Columbia</option>
        <option value="MB">Manitoba</option> -->
      </select>
    </template>

    <template
      v-else-if="dataType == 'checkbox'"
    >
      <div
        class="form-field"
        @click="onCheckboxClicked"
      >
        <!-- <div class="form-field-checkbox"></div> -->
        <div class="form-field-check-mark"></div>
      </div>
      <div class="form-checkbox-label" v-if="defaultText != ''">{{defaultText}}</div>
    </template>

    <input
      v-else
      v-on:input="(e) => { dataFilter(e) }"
      v-on:change="(e) => { dataFilter(e) }"
      v-bind:value="userInput"
      class="form-field"
      :autocomplete="autocomplete"
      :placeholder="defaultText"
      :type="dataType"
    />

  </div>

</template>

<script>
import { ref, computed, watch, onMounted } from 'vue';

export default {
  name: 'Input',
  props: {
    defaultText: String,
    dataType: String,
    customFilter: Function,
    regex: {
      type: String,
      default: null,
    },
    onInputCB: {
      default: null,
    },
    selectOptions: {
      type: Array,
      default: null,
    },
    showError: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'disabled',
    }
  },

  setup(props, context) {
    // Properties
    const userInput = ref(null);
    const error = ref(false);
    const checked = ref(false);

    // Vue Hooks


    // Methods
    const hasRegex = () => {
      let regexObj = new RegExp(props.regex);
      let dataValid = regexObj.test(userInput.value);

      // console.log('data val: ', userInput.value)

      if(!dataValid) {
        error.value = true;
        return false;

      } else {
        error.value = false;
        return true;

      }

    };

    const dataFilter = (e) => {
      let data = e.target.value;
      userInput.value = data;

      // console.log('whitespace check')
      if (/\S/.test(data) && data !== ''){

        // console.log('not whitespace')

        if( props.regex ){
          // console.log('regex check')
          hasRegex(e);
        }

        if( props.customFilter ){
          let cbBool = props.customFilter(userInput.value);
          error.value = cbBool;

        }

      } else {
        error.value = true;

      }

      context.emit('input', userInput.value);

      if( props.onInputCB != null ){
        // console.log('nonNull call')
        props.onInputCB(userInput.value, error.value);
      }

    };

    const onSelectInputChanged = (event) => {
      console.log('onSelectInputChanged', event);

      userInput.value = event.target.value;
      error.value = false;

      context.emit('input', userInput.value);

      props.onInputCB(error.value);

      // if( value )
    };

    const onCheckboxClicked = (event) => {
      console.log('onCheckboxClicked');
      console.log('prev value:', userInput.value);

      userInput.value = userInput.value === null ? true : !userInput.value;

      if( userInput.value === true ) {
        checked.value = true;
        error.value = false;
        // console.log('checked.value should be true');
      } else {
        checked.value = false;
        error.value = true;
        // console.log('checked.value should be false');
      }

      context.emit('input', userInput.value);

      props.onInputCB(checked.value, error.value);
    };

    return {
      userInput,
      error,
      checked,

      hasRegex,
      dataFilter,
      onSelectInputChanged,
      onCheckboxClicked,
    }
  },

}
</script>

<style lang="scss" scoped>

.form-field-wrapper {
  color: #000000;
  // border: solid 1.75px #000000;
  width: calc(100% - .5em);
  background: transparent;
  position: relative;
  overflow: hidden;
  margin: 0 auto .5em auto;
  height: 1.8rem;
  // border-radius: .25em;

  &.lrg {
    height: 6rem;
  }

  &.err {
    // border-color: red;
    border: none;

    .form-field {
      color: #ff0000;
    }

    .form-field-label {
      color: #ff0000;
    }
  }

}

.form-field {
  font-family: inherit;
  font-size: 1rem;
  text-transform: none;
  color: #000000;
  font-weight: inherit;
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;

  width: calc(100% - 15px);
  height: 100%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-47%);


  &::placeholder {
    font-family: inherit;
    font-size: inherit;
    text-transform: none;
    color: inherit;

  }

  input {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.form-select-default-text {
    opacity: 0;
  }

  &:focus, &:focus {
    outline: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active  {
    // border: 1px solid green;
    // -webkit-text-fill-color: green;
    // -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    color: #ffffff !important;
    -webkit-box-shadow: 0 0 0 30px rgba(0,0,255,0) inset !important;
    transition: background-color 9999s ease-in-out 0s, color 9999s ease-in-out 0s;
  }
}

.form-field-label {
  font-family: inherit;
  font-size: 1rem;
  text-transform: uppercase;
  color: #000000;
  font-weight: inherit;
  background: transparent;
  border: none;
  text-align: left;

  width: calc(100% - 15px);
  // height: 85%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

}

.form-field-wrapper.checked {
  .form-field-check-mark {
    display: block;
  }
}

.form-field-checkbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}

.form-field-check-mark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ff0;
  opacity: 0.6;
  display: none;
}

</style>
