import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  // {
  //   path: '/legal',
  //   name: 'Legal',
  //   // route level code-splitting
  //   // this generates a separate chunk (legal.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Legal.vue')
  // },
  {
    path: '/:slug?',
    name: 'User',
    component: Home
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {left:0, top:0};
  }
})

export default router
