<template>
  <Header />
  <router-view />
  <ShareOverlay v-if="shareOverlayVisible" />
  <SignUpOverlay v-if="signUpOverlayVisible" />
  <RotateOverlay />
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

import Header from '@/components/Header';
import ShareOverlay from '@/components/ShareOverlay';
import SignUpOverlay from '@/components/SignUpOverlay';
import RotateOverlay from '@/components/RotateOverlay';

export default {
  components: {
    Header,
    ShareOverlay,
    SignUpOverlay,
    RotateOverlay,
  },

  setup() {
    const store = useStore();

    return {
      shareOverlayVisible: computed(() => store.state.shareOverlay),
      signUpOverlayVisible: computed(() => store.state.signUpOverlay),
    }
  },
}

</script>

<style lang="scss" scoped>

</style>
