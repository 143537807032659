<template>
  <div class="sign-up-overlay fixed-overlay">
    <div class="suo__bg fixed-overlay-bg"></div>
    <div class="suo__content fixed-overlay-content">
      <div class="suo__content-dotted-bg dotted-bg"></div>
      <div class="suo__content-bg"></div>
      <div class="suo__content-container">
        <div
          :class="[
            'suo__content-form-success',
            (successForm === true) ? 'suo__content-form-success--visible' : ''
          ]"
        >
          <div class="suo__content-form-success__text">Thank you for submitting your request. You’ll get a notification when your friend request has been accepted.</div>
        </div>
        <div
          :class="[
            'suo__content-form-container', 
            (validForm === true) ? '' : 'invalid-form', 
            (submittingForm === true) ? 'invalid-form' : '', 
            errorForm === true ? 'error-form' : '',
            successForm === true ? 'success-form' : '',
          ]"
        >
          <div class="suo__content-header trajan-pro-bold-suo">ADD ME AS A FRIEND</div>
          <div class="suo__content-form__name">
            <!-- <div class="suo__content-form__first-name suo__form-input-container"> -->
              <!-- <input class="suo__form-input" placeholder="First Name" /> -->

              <Input
                :class="'suo__content-form__first-name suo__form-field-wrapper'"
                defaultText="First Name"
                dataType="text"
                regex="^[a-zA-Z\s]*$"
                :showError="userData.firstName.error"
                :onInputCB="(value, errLog) => { validateData('firstName', value, errLog) }"
              />
            <!-- </div> -->
            <!-- <div class="suo__content-form__last-name suo__form-input-container"> -->
              <!-- <input class="suo__form-input" placeholder="Last Name" /> -->
              <Input
                :class="'suo__content-form__last-name suo__form-field-wrapper'"
                defaultText="Last Name"
                dataType="text"
                regex="^[a-zA-Z\s]*$"
                :showError="userData.lastName.error"
                :onInputCB="(value, errLog) => { validateData('lastName', value, errLog) }"
              />
            <!-- </div> -->
          </div>
          <!-- <div class="suo__content-form__email suo__form-input-container"> -->
            <!-- <input class="suo__form-input" /> -->
            <div class="suo__content-form__email suo__form-field-wrapper suo__form-field-wrapper-flex">
              <div class="suo__form-field-icon"><img class="suo__form-field-icon-image" src="@/assets/input_email_icon.png" /></div>
              <div class="suo__form-field-text">
                <Input
                  defaultText="Email"
                  dataType="text"
                  :customFilter="validateEmail"
                  :showError="userData.email.error"
                  :onInputCB="(value, errLog) => { validateData('email', value, errLog) }"
                />
              </div>
            </div>
            <!-- <Input
              :class="'suo__content-form__email suo__form-field-wrapper'"
              defaultText="Email"
              dataType="text"
              :customFilter="validateEmail"
              :showError="userData.email.error"
              :onInputCB="(value, errLog) => { validateData('email', value, errLog) }"
            /> -->
          <!-- </div> -->
          <!-- <div class="suo__content-form__phone suo__form-input-container"> -->
            <!-- <input class="suo__form-input" /> -->
            <div class="suo__content-form__phone suo__form-field-wrapper suo__form-field-wrapper-flex">
              <div class="suo__form-field-icon"><img class="suo__form-field-icon-image" src="@/assets/input_phone_icon.png" /></div>
              <div class="suo__form-field-text">
                <Input
                  defaultText="Phone Number"
                  dataType="text"
                  regex="^[0-9\s]*$"
                  :customFilter="validatePhone"
                  :showError="userData.phone.error"
                  :onInputCB="(value, errLog) => { validateData('phone', value, errLog) }"
                />
              </div>
            </div>
            <!-- <Input
              :class="'suo__content-form__phone suo__form-field-wrapper'"
              defaultText="Phone Number"
              dataType="text"
              regex="^[0-9\s]*$"
              :customFilter="validatePhone"
              :showError="userData.phone.error"
              :onInputCB="(value, errLog) => { validateData('phone', value, errLog) }"
            /> -->
          <!-- </div> -->
          <div class="suo__form-legal-container">
            <Input
              :class="'suo__content-form__checkbox'"
              defaultText=""
              dataType="checkbox"
              :showError="userData.legal.error"
              :onInputCB="(value, errLog) => { validateData('legal', value, errLog) }"
            />
            <div class="suo__form-legal-text">I accept the <a href="https://www.lionsgate.com/legal" target="_blank">Terms & Conditions</a></div>
            <!-- <div class="suo__form-legal-text">I accepted the <a href="https://www.lionsgate.com/legal/privacy-policy" target="_blank">Privacy Policy</a>.</div> -->
          </div>

          <div class="suo__content-divider"></div>

          <div class="suo__content-header-top trajan-pro-bold-suo">BE IN MY TOP 8</div>
          <div class="suo__content-subheader-top trajan-pro-bold-suo">(OPTIONAL)</div>

          <!-- <div class="suo-content-form__uploadImage-btn">Upload Image</div> -->
            <!-- <Input
              :class="'suo-content-form__uploadImage-btn'"
              defaultText=""
              dataType="file"
              :showError="userData.image.error"
              :onInputCB="(value, errLog) => { validateData('image', value, errLog) }"
            /> -->

          <label class="suo-content-form__uploadImage-btn-label" for="uploadImage">{{message}}</label>
          <input class="suo-content-form__uploadImage-btn-input" id="uploadImage" accept="image/png, image/gif, image/jpeg" type="file" hidden @change="onImageUpload">

          <div class="suo__form-legal-container">
            <Input
              :class="'suo__content-form__checkbox'"
              defaultText=""
              dataType="checkbox"
              :showError="userData.legal.error"
              :onInputCB="(value, errLog) => { validateData('rules', value, errLog) }"
            />
            <div class="suo__form-legal-text">I accept the <a href="https://www.lionsgate.com/sweepstakes/mycagespace-top8" target="_blank">Rules and Regulations</a></div>
            <!-- <div class="suo__form-legal-text">I accepted the <a href="https://www.lionsgate.com/legal/privacy-policy" target="_blank">Privacy Policy</a>.</div> -->
          </div>

          <div class="suo-content-form__submit-btn" @click="handleSubmitBtnClicked">Submit</div>
            <div class="suo-content-form__error">{{errorMessage}}</div>
            <!-- <div
              class="suo-content-form__success">Thank you for submitting.</div> -->
          
        </div>
        <div class="suo__content-close-btn" @click="closeOverlay"><img src="@/assets/overlay_close_btn.png" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex'
// import { submitUserInfo } from '@/api/user';

import Input from '@/components/Input';

const NAMESPACE = '[SignUpOverlay]';

export default {
  methods: {

  },
  components: {
    Input,
  },

  setup() {
    const store = useStore();

    let userImageData = null;

    const validForm = ref(false);
    const errorForm = ref(false);
    const successForm = ref(false);
    const submittingForm = ref(false);
    const userData = ref({
      firstName: {
        value: null,
        error: null,
      },
      lastName: {
        value: null,
        error: null,
      },
      email: {
        value: null,
        error: null,
      },
      phone: {
        value: null,
        error: null,
      },
      legal: {
        value: false,
        error: null
      },
      rules: {
        value: false,
        error: null
      },
      image: {
        value: null,
        error: false
      }
    });
    
    const duplicateError = ref("Oops! It looks like you already submitted your friend request.");
    const generalError = ref("Oops! Something went wrong. Please try again.");
    const errorMessage = ref('');
    const message = ref("Upload Image");
    
    const closeOverlay = () => {
      console.log(store);
      // console.log(this.$store);
      store.commit('enabledScroll', {value:true});
      store.commit('updateSignUpOverlay', {value:false});
    }

    const validateData = (inputKey, value, error) => {
      console.log('inputKey:', inputKey);
      console.log(error);
      let targetVal = userData.value[inputKey];
      targetVal.value = value;
      targetVal.error = error;

      validateUserData();

    };

    const validateUserData = () => {
      // console.log('validateUserData');

      let isValid = true;

      for( let data in userData.value ) {
        let currData = userData.value[data];
        console.log('data:', data);
        console.log('currData:', currData);
        if( data == 'image' || data == 'rules' ) {
          console.log('checking userData image');
          if( userData.value.rules.value == true && userData.value.image.value == null ) {
            isValid = false;
          } else if( userData.value.image.value != null && userData.value.rules.value != true ) {
            isValid = false;
          }
          // if( !(userData.value.rules.value == true && userData.value.image.value != null) ) {
          //   isValid = false;
          // }
          
          // if( userData.value.rules.value === true userData.value.image.value !)

        } else {
          if( currData.value == null || currData.value == false || currData.error == true ) {
            isValid = false;
          }
        }
        // if (
        //   (currData.value == null || currData.value == false || currData.error == true)
        //   && currData != userData.value.image)
        // {
        //   isValid = false;
        // }
      }

      console.log('isValid:', isValid);

      validForm.value = isValid;
    };

    const validateEmail = (value) => {
      const _emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


      if (_emailRegex.test(value)) {
        //valid
        console.log('valEmail')
        return false;

      } else {
        //invalid
        console.log('invalEmail')
        return true;

      }

      return true;

    };

    const validatePhone = (value) => {
      // console.log('validatePhone');

      const phoneRegex = /[0-9]/;
      const notDigitRegex = /[^(0-9\-)]/;
      let arr = value.split('');
      let count = 0;

      for( let i=0; i<arr.length; i++ ) {
        if( notDigitRegex.test(arr[i]) ) {
          return true;
        } else if( phoneRegex.test(arr[i]) ) {
          count++;
        }
      }

      // console.log('number of digits:', count);

      // return true;

      if( count >= 10 ) {
        return false;
      }

      return true;
    };

    const onImageUpload = (element) => {
      console.log(element);
      console.log("Image Selected");

      message.value = "Image uploaded!";

      const file = element.srcElement.files[0];
      console.log(file);

      const reader = new FileReader();
      reader.onloadend = function() {
        // console.log('RESULT', reader.result);
        storeImage(reader.result);
      }

      const storeImage = (result) => {
        userImageData = result;
        // console.log(userImageData);
        userData.value.image.value = result;

        validateUserData();
      }

      reader.readAsDataURL(file);

    }

    const handleSubmitBtnClicked = () => {
      if( validForm.value !== true ) {
        return;
      }

      if( submittingForm.value === true ) {
        return;
      }

      errorForm.value = false;
      submittingForm.value = true;
      successForm.value = false;

      console.log('valid form');
      // console.log(userImageData);

      const user = {
        firstName: userData.value.firstName.value,
        lastName: userData.value.lastName.value,
        email: userData.value.email.value,
        phone: userData.value.phone.value,
        phase: 2,
        topEight: 0,
      }

      if( userImageData != null ) {
        user.topEight = 1;
        user.profileImage = userData.value.image.value;
      }

      store.dispatch('submitUserInfo', user)
        .then((response) => {
          console.log('submitUserInfo done');
          submittingForm.value = false;

          console.log(response);

          if( response.code == 200 ) {
            errorForm.value = false;
            successForm.value = true;
          } else {
            if( response.error === 'DUPLICATE_DATA' ) {
              assignErrorMessage(true);
            } else {
              assignErrorMessage(false);
            }

            errorForm.value = true;
          }
        })
        .catch((error) => {
          assignErrorMessage(false);
          errorForm.value = true;
        })
    }

    const assignErrorMessage = (duplicate) => {
      if( duplicate === true ) {
        errorMessage.value = duplicateError.value;
      } else {
        errorMessage.value = generalError.value;
      }
    }

    onMounted(() => {
      console.log(NAMESPACE, 'mounted');

      // Debugging purpose
      // userData.value.firstName.value = 'Test';
      // userData.value.lastName.value = 'Two';
      // userData.value.email.value = 'christian+testtwo@avatarlabs.com';
      // userData.value.phone.value = '1112223333';
      // validForm.value = true;
    })

    return {
      userData,
      validForm,
      errorForm,
      successForm,
      submittingForm,

      validateData,
      validateUserData,
      validateEmail,
      validatePhone,

      duplicateError,
      generalError,
      errorMessage,

      onImageUpload,
      message,
      handleSubmitBtnClicked,
      
      closeOverlay,
    }
  },
}
</script>

<style lang="scss" scoped>

.sign-up-overlay {

}

.suo__content {
  position: relative;
  // background: #ffffff;
  color: $overlay-input-subtext-color;
  padding: 1em 1em;
  display: inline-block;
}

.suo__content-bg {
  position: absolute;
  top: 1px;
  left: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background: $overlay-bg-color;
}

.suo__content-container {
  position: relative;
  // padding: 4em 4em 2em 2em;
  padding: 4em 5em;
  border: 1px solid $overlay-border-color;
}

.suo__content-form__text {
  text-transform: none;
}

.suo__content-form-success {
  display: none;
  position: relative;
  font-size: 1em;
  margin: 0em auto 0 auto;
  min-width: 26em;
  max-width: 32em;

  &.suo__content-form-success--visible {
    display: block;
  }

  
}

.suo__content-form-success__text {
  position: relative;
  font-size: 1.5em;
  padding: 0 0 1em 0;
}

.suo__content-header {
  font-size: 2.1em;
  font-weight: 600;
  text-align: center;
  margin: 0 0 0.3em 0;
}

.suo__content-divider {
  height: 1px;
  width: 100%;
  background-color: $overlay-input-divider;
  margin: 2em 0 2em 0;
}

.suo__content-header-top {
  font-size: 2.1em;
  font-weight: 600;
  text-align: center;
  margin: 0 0 0em 0;
}

.suo__content-subheader-top {
  font-size: 1.7em;
  font-weight: 600;
  text-align: center;
  margin: 0 0 0.8em 0;
}

.suo__content-form-container {
  position: relative;
  font-size: 1em;
  margin: 0em auto 0 auto;
  min-width: 26em;
  max-width: 32em;

  &.invalid-form {

    .suo-content-form__submit-btn {
      opacity: 0.7;
      cursor: default;
    }
  }

  &.error-form {
    .suo-content-form__error {
      // visibility: visible;
      display: block;
    }
  }

  &.success-form {
    display: none;

    .suo-content-form__success {
      display: block;
    }
  }
}

.suo__content-form__name {
  position: relative;
  display: flex;
  justify-content: space-between;
  text-transform: none;

  // .suo__form-input-container {
  //   width: 50%;

  //   &:first-child {

  //   }

  //   &:last-child {

  //   }
  // }
}

// .suo__form-input-container {
//   position: relative;
//   background: $overlay-bg-color;
//   border: 1px solid $overlay-border-color;
//   padding: 0.5em 0.5em 0.2em 0.5em;
  
//   &.suo__content-form__first-name {
//     margin-right: 0.25em;
//   }

//   &.suo__content-form__last-name {
//     margin-left: 0.25em;
//   }

//   &.suo__content-form__email {
//     margin: 0.5em 0 0 0;
//     min-width: 34em;
//   }

//   &.suo__content-form__phone {
//     margin: 0.5em 0 0 0;
//   }
// }

.suo__form-field-wrapper-email {
  position: relative;
  background: $overlay-input-bg-color;
  border: 1px solid $overlay-input-border-color;
  padding: 0.75em 0.75em 0.75em 0.75em;
  color: $overlay-input-text-color;
  font-weight: 900;
  height: 2em;
  width: calc(100% - 1.5em);
}

.suo__form-field-icon {
  flex-basis: 5%;
  margin-top: 0.2em;
}

.suo__form-field-icon-image {
  width: 14px;
  margin: 0;
  padding: 0;
}

.suo__form-field-text {
  flex-basis: 80%;
  margin-left: 0;
}

.suo__form-field-wrapper-flex {
  display: flex;
}

.suo__form-field-wrapper {
  position: relative;
  background: $overlay-input-bg-color;
  border: 1px solid $overlay-input-border-color;
  padding: 0.75em 0.75em 0.5em 0.75em;
  color: $overlay-input-text-color;
  font-weight: 900;
  height: 2em;
  width: calc(100% - 1.5em);

  &.suo__content-form__first-name {
    margin-right: 0.25em;
    width: calc(50% - 0.25em);

    &.err {
      width: calc(50% - 0.25em - 2px);
    }
  }

  &.suo__content-form__last-name {
    margin-left: 0.25em;
    width: calc(50% - 0.25em);

    &.err {
      width: calc(50% - 0.25em - 2px);
    }
  }

  &.suo__content-form__email {
    margin: 0.5em 0 0 0;
  }

  &.suo__content-form__phone {
    margin: 1em 0 0 0;
  }

  &:deep(.form-field) {
    color: $overlay-input-text-color;
    width: calc(100% - 2em);
  }

  &.err {
    border: 2px solid $profile-color-red;
    width: calc(100% - 1.5em - 2px);

    &:deep(.form-field) {
      color: $overlay-input-text-color;
    }
  }
}

.suo__form-input {
  position: relative;
  background: transparent;
  color: $overlay-input-text-color;
  border: none;
}

.suo__form-legal-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5em auto 0 auto;
  width: 84%;

  .suo__content-form__checkbox {
    padding: 0;
    margin: 0;
    width: 1.3em;
    height: 1.3em;
    background: transparent;
    border: 1px solid $overlay-input-border-color;
    overflow: visible;
    cursor: pointer;

    &:deep(.form-field) {
      width: 100%;
    }

    &:deep(.form-field-check-mark) {
      width: 3em;
      height: 3em;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      background: url('~@/assets/sign_up_legal_checkmark.png') no-repeat;
      background-size: cover;
    }
  }

  .suo__form-legal-text {
    font-weight: 600;
    margin: 0 0 0 1em;


    a {
      color: $profile-color-online-status;
    }
  }
}

.suo-content-form__submit-btn {
  position: relative;
  margin: 1em auto 0 auto;
  color: #ffffff;
  text-shadow:
   -0.25px -0.25px 0 #000,  
    0.25px -0.25px 0 #000,
    -0.25px 0.25px 0 #000,
     0.25px 0.25px 0 #000;
  -webkit-box-shadow:0 0 20px $overlay-border-color; 
  -moz-box-shadow: 0 0 20px $overlay-border-color; 
  box-shadow:0 0 20px $overlay-border-color;
  letter-spacing: 0.1em;
  border-radius: 5px;
  border: solid 0.5px #000000;
  // background: #8c1414;
  background-image: linear-gradient(#0ce24d, #048d2d);
  text-align: center;
  font-size: 1.2em;
  font-weight: 400;
  line-height: 1em;
  padding: 1.2em 0.5em 1.3em 0.5em;
  cursor: pointer;
}

.suo-content-form__uploadImage-btn-label {
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 1em auto 0 auto;
  color: $overlay-input-text-color;
  letter-spacing: 0.1em;
  border-radius: 5px;
  border: solid 0.5px #000000;
  background-image: linear-gradient(#f2f3f6, #8e97b3);
  // background: #8c1414;
  text-align: center;
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1em;
  padding: 1.2em 0.5em 1.3em 0.5em;
  cursor: pointer;
}

.suo-content-form__uploadImage-btn-input {
  display: none;
}

.suo-content-form__uploadImage-btn {
  position: relative;
  margin: 1em auto 0 auto;
  color: $overlay-input-text-color;
  letter-spacing: 0.1em;
  border-radius: 5px;
  border: solid 0.5px #000000;
  background-image: linear-gradient(#f2f3f6, #8e97b3);
  // background: #8c1414;
  text-align: center;
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1em;
  padding: 1.2em 0.5em 1.3em 0.5em;
  cursor: pointer;
}

.suo-content-form__error {
  position: relative;
  margin: 1em 0 0 0;
  // color: #8c1414;
  color:white;
  font-size: 1.1em;
  display: none;
}

.suo-content-form__success {
  position: relative;
  margin: 1em 0 0 0;
  display: none;
}

.suo__content-close-btn {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;

  img {
    width: 100%;
  }
}


</style>