<template>
  <div class="share-overlay fixed-overlay">
    <div class="so__bg fixed-overlay-bg"></div>
    <div class="so__content fixed-overlay-content">
      <div class="so__content-dotted-bg dotted-bg"></div>
      <div class="so__content-bg"></div>
      <div class="so__content-container">
        <div class="so__content-header">Share with your friends</div>
        <div class="so__content-shares-container">
          <div class="so__content-share" @click="downloadShareImage"><a href="share_image.jpg" target="_blank" download><img src="@/assets/share_icon_download.png" /></a></div>
          <div class="so__content-share" @click="shareFacebook"><img src="@/assets/share_icon_facebook.png" /></div>
          <div class="so__content-share" @click="shareTwitter"><img src="@/assets/share_icon_twitter.png" /></div>
        </div>
        <div class="so__content-close-btn" @click="closeOverlay"><img src="@/assets/overlay_close_btn.png" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex'


export default {
  setup() {
    const store = useStore();

    const downloadShareImage = () => {
      
    }

    const shareTwitter = () => {
      let shareUrl = window.location.origin;
      let message = '';
      let url = 'https://twitter.com/intent/tweet?text='+encodeURIComponent(message)+'&url='+encodeURI(shareUrl);
      window.open(url, '_blank');
    }

    const shareFacebook = () => {
      let shareUrl = window.location.origin;
      let url = 'https://www.facebook.com/sharer/sharer.php?u='+(shareUrl);

      window.open(url, '_blank');
    }

    const closeOverlay = () => {
      console.log(store);
      // console.log(this.$store);
      store.commit('updateShareOverlay', {value:false});
      store.commit('enabledScroll', {value:true});
    }


    return {
      downloadShareImage,
      shareFacebook,
      shareTwitter,
      closeOverlay,
    }
  },
}
</script>

<style lang="scss" scoped>

.share-overlay {

}

.so__content {
  position: relative;
  background: #000000;
  color: #ffffff;
  padding: 1em;
  display: inline-block;
  max-width: 90%;
}

.so__content-bg {
  position: absolute;
  top: 1px;
  left: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background: $overlay-bg-color;
}

.so__content-container {
  position: relative;
  padding: 4em 2em 3em 2em;
  border: 1px solid $overlay-border-color;
}

.so__content-header {
  font-size: 2.5em;
  font-weight: 600;
  font-weight: 700;
  font-style: normal;
  color: #fef22d;
  text-transform: uppercase;
}

.so__content-shares-container {
  position: relative;
  font-size: 1em;
  display: flex;
  justify-content: space-between;
  margin: 1em auto 0 auto;
  width: 75%;
}

.so__content-share {
  position: relative;
  font-size: 2em;
  padding: 0;
  // border-radius: 50%;
  // background: #000000;
  color: #b79449;

  width: 4em;
  height: 4em;
  cursor: pointer;

  & img {
    width: 100%;
  }

  // & .fab {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translateX(-50%) translateY(-50%);
  // }

  // &:first-child {
  //   margin-left: 0;
  // }

  // &:last-child {
  //   margin-right: 0;
  // }
}

.so__content-close-btn {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;

  & img {
    width: 100%;
  }
}

@include tablet {

}

@include mobile {
  .so__content-header {
    font-size: 2em;
  }

  .so__content-share {
    font-size: 1.6em;
  }
}

</style>